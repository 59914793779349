<template>
    <base-page v-bind:schemaName="'product'" v-bind:isHideRemove="true">
        <template v-slot:panel-left="{entity}">
            <div class="mb-3 text-center">
                <img v-bind:src="entity.image ?? require('@/assets/not-pictured-large.png')" class="img-thumbnail rounded mb-2 image-size">
                <input type="url" id="image" class="form-control mb-2" v-model="entity.image">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Название</label>
                <input type="text" class="form-control" v-model="entity.name">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Цена опт.</label>
                <input min=0 type="number" class="form-control" v-model="entity.wholeSalePrice">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Цена розница</label>
                <input type="number" class="form-control" v-model="entity.price">
            </div>            
            <div class="mb-3 text-left">
                <label class="form-label">Код</label>
                <input type="text" class="form-control" v-model="entity.code" required>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Категория</label>
                <select :disabled="categories.length === 0" class="form-control" v-model="entity.categoryId">
                    <option v-for="option in categories" v-bind:key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-left">
                <div>
                    <label class="form-label">Акционный:</label>
                </div>
                <input type="checkbox" v-model="entity.isPromotion">
                <label class="form-label">&nbsp; {{ entity.isPromotion ? 'Да' : 'Нет' }} </label>
            </div>
        </template>

        <template v-slot:header="{entity}">
            <div class="row m-2">
                <div class="col-sm mb-3 mt-2 text-left">
                    <label class="form-label">Описание</label>
                    <textarea class="form-control" v-model="entity.description" />
                </div>
            </div>
            <div class="row m-2">
                <div class="col-sm mb-3 mt-2 text-left">
                    <label class="form-label">Комментарий</label>
                    <textarea class="form-control" v-model="entity.comment" />
                </div>
            </div>
            <div class="row m-2">
                <div class="col-sm mb-3 mt-2 text-left">
                    <label class="form-label">Размерна сетка</label>
                    <textarea class="form-control" v-model="entity.dimensionalGrid" />
                </div>
            </div>
        </template>
            
        <template v-slot:body="{action}">
            <product-remains-detail v-bind:rootId="this.$route.params.id" v-bind:rootColumn="'ProductId'" v-bind:enabled="action=='update'"></product-remains-detail>
        </template>
    </base-page>
</template>


<script>
import ProductRemainsDetail from "@/views/details/remains/ProductRemainsDetail.vue";
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "ProductPage",
    components:{ BasePage, ProductRemainsDetail },
    data(){
        return{
            categories:[]
        }
    },
    methods:{
        loadLookups() {
            this.$store.dispatch('section', { table: "productcategory" })
            .then((response) => {
                this.categories = response.data.data;
            });
        }
    },
    mounted(){
        this.loadLookups();
    }
}

</script>
<style scoped>
.image-size{
    max-height: 300px;
    width: 300px;
}
</style>