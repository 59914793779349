<template>
    <t-section v-bind:schemaName="'contact'" v-bind:fields="fields" v-bind:headers="headers" v-bind:isHideChart="true">
        <template v-slot:custom>
            <sender-detail/>
        </template>
    </t-section>
</template>

<script>
    import SenderDetail from "@/views/details/messages/SenderDetail.vue";
    import TSection from '@/components/Sections/SectionV3.vue';
    export default {
        name: "SectionContact",
        components:{
            TSection,
            SenderDetail
        },
        data() {
            return {
                headers:[
                    { field:"surname", name: this.$filters.localize("ContactPage.fields.surname"), sort:true, router: { field:"id", name:"ContactPage" }},
                    { field:"name", name:this.$filters.localize("ContactPage.fields.name"), sort:true, router: { field:"id", name:"ContactPage" }},
                    { field:"mobilePhone", name:this.$filters.localize("ContactPage.fields.mobilePhone"), sort:true, filter:true, router: { field:"id", name:"ContactPage" }},
                    { field:"status.name", name:"Статус", sort:true, filter:true, router: { field:"id", name:"ContactPage" }}
                ],
                fields: [
                    { field: "mobilePhone", name: this.$filters.localize("ContactPage.fields.mobilePhone"), type:"string" },
                    { field: "surname", name: "ФИО", type:"string" },
                    { field: "code", name: "Код", type:"string" }
                ]
            }
        }
    }
</script>