<template>
    <t-section v-bind:schemaName="'product'"
       v-bind:headers="headers"
       v-bind:isCustomRow="true"
       v-bind:fields="fields">

        <template v-slot:buttons>
            <button type="button"
               :disabled="isSyning"
               class="btn btn-sm btn-outline-primary"
               v-on:click="sync">Синхронізувати</button>
        </template>
        <template v-slot:count>
            <div class="col-sm">Сумма опт: {{ totalWholeSale }}</div>
        </template>
        <template v-slot:body="{ data, headers, getRowValue, router }">
            <tbody v-for="row of data"
               v-bind:key="row.id">
                <tr>
                    <td v-on:click.prevent="getRemains(row.id)"
                       v-if="remains.filter(x => x?.productId == row.id).length == 0">+</td>
                    <td v-on:click.prevent="removeRemains(row.id)"
                       v-else>-</td>
                    <td v-for="header of headers"
                       v-bind:key="header.field + row.id"
                       v-on:click.prevent="router(getRowValue(row, header.router), header.router.name)">
                        {{ getRowValue(row, header) }}
                    </td>
                </tr>
                <td :colspan="headers.length + 1"
                   v-if="remains?.filter(x => x?.productId == row.id).length > 0">
                    <table class="col">
                        <thead>
                            <tr class="col">
                                <th class="text-center">Сумма опт: {{ remains.filter(x => x?.productId == row.id).map(x =>
                                    x.quantity)
                                    .reduce((total, amount) => total += amount * row.wholeSalePrice, 0) }}</th>
                                <th class="text-center">Сумма пар: {{ remains.filter(x => x?.productId == row.id).map(x =>
                                    x.quantity)
                                    .reduce((total, amount) => total + amount, 0) }}</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr class="rounded shadow">
                                <th class="text-center">
                                    Размер
                                </th>
                                <th class="text-center">
                                    Количество
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="row of remains.filter(x => x?.productId == row.id)"
                               v-bind:key="row.id">
                                <td>
                                    {{ row.character?.code }}
                                </td>
                                <td>
                                    {{ row.quantity }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tbody>
        </template>
    </t-section>
</template>

<script>
import TSection from '@/components/Sections/Section.vue';
export default {
    name: "ProductSection",
    components: {
        TSection,
    },
    methods: {
        getRemains(rootId) {
            this.$store.dispatch('getFilter', { table: "productRemains", body: { id: rootId, rootColumn: "ProductId" } })
                .then((response) => {
                    this.remains = [...this.remains, ...response.data.data];
                });
        },
        removeRemains(rootId) {
            this.remains = this.remains.filter(x => x?.productId !== rootId);
        },
        calculateTotalRemains() {
            this.$store.dispatch('get', { table: "productRemains", method: "totalWholeSale" })
                .then(response => {
                    this.totalWholeSale = response.data.data;
                });
            return 0;
        },
        async sync() {
            this.isSyning = true;
            await this.$store.dispatch('post', { table: 'product', method: 'sync', body: {} });
            this.$toast.success("Синхронізація успішно запущена, можливо займе 10-15 хвилин.");
        },

        reset() {
            this.isSyning = false;
        }
    },
    mounted() {
        this.reset();
        this.calculateTotalRemains();
    },
    data() {
        return {
            headers: [
                { field: "name", name: "Название", sort: true, router: { field: "id", name: "ProductPage" } },
                { field: "code", name: "Код", sort: true, router: { field: "id", name: "ProductPage" } }
            ],
            remains: [],
            totalWholeSale: 0,
            isSyning: false,
            fields: [
                { field: "code", name: "Код", type: "number" },
                { field: "isPromotion", name: "Акционый", type: "lookup", lookup: [{ id: "true", name: "Да" }, { id: "false", name: "Нет" }] }
            ]
        }
    }
}
</script>